










import {Component, Vue} from 'vue-property-decorator';
import ContentCreatorAgreement from '@/views/ContentCreatorAgreement.vue';
import Modal from '@/components/Modal.vue';

@Component({
  components: {Modal, ContentCreatorAgreement},
})
export default class ContentCreatorAgreementModal extends Vue {
  showPopup = false;
}

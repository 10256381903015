





























































































import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import {required, email} from 'vuelidate/lib/validators';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {Modal},
  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      username: {
        required,
      },
    },
  },
})
export default class EditModelProfile extends Vue {
  showModal = false;
  isRequestSend = false;
  form = {
    email: this.user.email,
    name: this.user.name,
    username: this.user.username,
    sex: this.user.sex,
    description: this.user.description,
    age: this.user.age,
    languages: this.user.languages,
    hometown: this.user.hometown,
    ethnicity: this.user.ethnicity,
    sexualPreference: this.user.sexualPreference,
    weight: this.user.weight,
    height: this.user.height,
    hair: this.user.hair,
    publicHair: this.user.publicHair,
    eyes: this.user.eyes,
    bust: this.user.bust,
    twitterHandle: this.user.twitterHandle,
    instagramHandle: this.user.instagramHandle,
  };
  genders = ['male', 'female', 'MTF', 'FTM'];
  orientations = ['straight', 'gay', 'bisexual'];
  oldLanguages = this.form.languages ? this.form.languages.split(',') : [];
  newLanguages: Array<{name: string}> = [];
  emailError = false;
  usernameError = false;

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  addNewLanguage() {
    this.newLanguages.push({name: ''});
  }

  deleteNewLanguage(index: number) {
    this.newLanguages.splice(index, 1);
  }

  deleteOldLanguage(index: number) {
    this.oldLanguages.splice(index, 1);
  }

  concatLanguages() {
    let newLangs = '';
    this.newLanguages.forEach((value, index) => {
      if (value.name.length) {
        newLangs = index === 0 ? value.name : newLangs + ',' + value.name;
      }
    });
    const oldLang =
      this.oldLanguages.length && newLangs ? this.oldLanguages.join(',') + ',' : this.oldLanguages.join(',');
    return oldLang + newLangs ? oldLang + newLangs : undefined;
  }

  save() {
    this.$v.form.$touch();
    if (!this.$v.form.$invalid && !this.isRequestSend) {
      this.isRequestSend = true;
      this.checkUnique()
        .then(() => {
          this.form.languages = this.concatLanguages();
          this.form.name = this.form.name.trim();
          const data = {user: {...this.form, _id: this.user._id}};
          vxm.user
            .updateProfile(data)
            .then(() => {
              this.showModal = false;
              this.newLanguages = [];
              this.oldLanguages = this.form.languages ? this.form.languages.split(',') : [];
            })
            .catch((error) => {
              return error;
            })
            .then(() => {
              this.isRequestSend = false;
            });
        })
        .catch(() => {
          this.isRequestSend = false;
        });
    }
  }

  checkUnique() {
    return Promise.all([this.checkEmail(), this.checkUsername()]);
  }

  checkEmail() {
    if (this.form.email === this.user.email) {
      return Promise.resolve();
    }

    return vxm.user.checkEmail(this.form.email).catch(() => {
      this.emailError = true;
      return Promise.reject();
    });
  }

  checkUsername() {
    if (this.form.username === this.user.username) {
      return Promise.resolve();
    }

    return vxm.model
      .checkUsername(this.form.username.trim())
      .then((res) => {
        this.form.username = res.data.username;
        return Promise.resolve();
      })
      .catch(() => {
        this.usernameError = true;
        return Promise.reject();
      });
  }

  onBlurEmail() {
    (this.$v.form as any).email.$touch();
    this.emailError = false;
  }

  onBlurUsername() {
    (this.$v.form as any).username.$touch();
    this.usernameError = false;
  }
}

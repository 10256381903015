






























































import {Component, Mixins} from 'vue-property-decorator';
import BlobToFileMixin from '@/mixins/BlobToFileMixin';
import CroppieMixin from '@/mixins/CroppieMixin';
import EditModelProfile from '@/components/modals/EditModelProfile.vue';
import EditModelBankAccount from '@/components/modals/EditModelBankAccount.vue';
import EditModelSubscription from '@/components/modals/EditModelSubscription.vue';
import {vxm} from '@/store';
import {ModelDataInterface} from '@/types/userDataInterface';
import Loader from '@/components/Loader.vue';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import NumbersMixin from '@/mixins/NumbersMixin';

@Component({
  components: {
    Loader,
    EditModelProfile,
    EditModelBankAccount,
    EditModelSubscription,
  },
})
export default class ModelProfileLayout extends Mixins(
  CroppieMixin,
  BlobToFileMixin,
  DefaultAvatarMixin,
  NumbersMixin,
) {
  isRequestSend = false;

  get model(): ModelDataInterface {
    return vxm.user.data;
  }

  get isFansCounts() {
    return this.model.isShowFans;
  }

  get totalLikes() {
    return this.model ? this.model.totalLike : 0;
  }

  get totalViews() {
    return this.model ? this.model.totalView : 0;
  }

  get totalSubs() {
    return this.model.totalSubscriber ? this.model.totalSubscriber : 0;
  }

  mounted() {
    vxm.user.getMe().catch((error) => {
      return error;
    });
  }

  updateAvatar() {
    this.crop().then(() => {
      if (this.croppieImage) {
        const user = JSON.parse(JSON.stringify(this.model));
        user.imageFullPath = this.croppieImage;
        vxm.user.setUserData(user);
        const file = this.dataURLtoFile(this.croppieImage, 'avatar');
        vxm.general
          .getPreSignedPostData({
            key: file.name,
            mediaType: file.type,
            type: 'user.avatar',
          })
          .then((res) => {
            const data = new FormData();
            Object.keys(res.data.fields).forEach((key) => {
              data.append(key, res.data.fields[key]);
            });
            data.append('file', file);

            vxm.general
              .directUpload({
                url: res.data.url,
                data,
              })
              .then(() => {
                vxm.user
                  .updateAvatarDirectUpload({
                    photoUrl: res.data.fileToken,
                  })
                  .catch((error) => {
                    return error;
                  });
              })
              .catch((error) => {
                return error;
              });
          })
          .catch((error) => {
            return error;
          });
      }
    });
  }

  switchSubsShown() {
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      const data = {
        user: {
          _id: this.model._id,
          isShowFans: !this.model.isShowFans,
        },
      };
      return vxm.user
        .updateProfile(data)
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}

































































































































































import {Component, Mixins, Vue} from 'vue-property-decorator';
import EditModelProfile from '@/components/modals/EditModelProfile.vue';
import {vxm} from '@/store';
import {ModelDataInterface} from '@/types/userDataInterface';
import PasswordChangeModal from '@/components/modals/PasswordChangeModal.vue';
import Loader from '@/components/Loader.vue';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';
import Vuelidate from 'vuelidate';
import {maxLength} from 'vuelidate/lib/validators';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import ModelProfileDetails from '@/views/model-views/profile/ModelProfileDetails.vue';
import ViewModelAgreementModal from '@/components/modals/ViewModelAgreementModal.vue';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';
Vue.use(Vuelidate);

@Component({
  components: {
    Loader,
    PasswordChangeModal,
    EditModelProfile,
    ForgotPasswordModal,
    ModelProfileDetails,
    ViewModelAgreementModal,
    ContactUsModalNew,
  },
  validations: {
    defaultTweetText: {
      maxLength: maxLength(280),
    },
  },
})
export default class ModelProfileGeneral extends Mixins(CopyToClipboardMixin) {
  isVideoUpload = false;
  isWelcomeVideoActive = this.user.welcomeOption === 'video';
  isRequestSend = false;
  defaultTweetText = this.user.defaultTweetText ? this.user.defaultTweetText : '';
  scheduleInterval = this.user.scheduleInterval ? this.user.scheduleInterval : 1;
  showForgotPassword = false;
  showContactUs = false;

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  get profileLink(): string {
    return window.location.origin + '/' + this.user.username;
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }

  toggleWelcomeVideo() {
    this.updateProfile({welcomeOption: this.isWelcomeVideoActive ? 'video' : 'disabled'});
  }

  uploadWelcomeVideo(e) {
    if (!e.target.files.length) {
      return;
    }

    const file: File = e.target.files[0];
    const type = file.type.split('/')[0];

    if (type !== 'video') {
      this.$notify({
        group: 'foo',
        title: this.$t('error.invalid-file') as string,
        type: 'error',
        duration: 5000,
        text: this.$t('error.invalid-file-description') as string,
      });

      return;
    }

    this.isVideoUpload = true;

    vxm.general
      .getPreSignedPostData({
        key: file.name,
        mediaType: file.type,
        type: 'performer.welcome.video',
      })
      .then((res) => {
        const data = new FormData();
        Object.keys(res.data.fields).forEach((key) => {
          data.append(key, res.data.fields[key]);
        });
        data.append('file', file);

        vxm.general
          .directUpload({
            url: res.data.url,
            data,
          })
          .then(() => {
            vxm.model
              .uploadWelcomeVideo({
                welcomeVideo: res.data.fileToken,
              })
              .then(() => {
                vxm.user
                  .getMe()
                  .then(() => {
                    this.isVideoUpload = false;
                  })
                  .catch((error) => {
                    return error;
                  });
              })
              .catch((error) => {
                return error;
              });
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        return error;
      });
  }

  toggleAutoPost() {
    this.updateProfile({autoPostTwitter: !this.user.autoPostTwitter});
  }

  toggleScheduleAutoPost() {
    this.updateProfile({autoPostScheduleTwitter: !this.user.autoPostScheduleTwitter});
  }

  toggleSalesMarketingEmails() {
    this.updateProfile({settings: {sendSalesMarketingEmails: !this.user.settings.sendSalesMarketingEmails}});
  }

  changeScheduleInterval() {
    this.updateProfile({scheduleInterval: this.scheduleInterval});
  }

  connectTwitter() {
    vxm.model.connectTwitter({callbackUrl: window.location.href}).catch((error) => {
      return error;
    });
  }

  disconnectTwitter() {
    vxm.model
      .disconnectTwitter()
      .then(() => {
        vxm.user.getMe();
      })
      .catch((error) => {
        return error;
      });
  }

  saveDefaultTweetText() {
    this.updateProfile({defaultTweetText: this.defaultTweetText});
  }

  updateProfile(fields) {
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      const data = {user: fields};
      data.user._id = this.user._id;
      vxm.user
        .updateProfile(data)
        .then((res) => {
          if (res.status === 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Success!',
              text: 'Information was updated',
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}

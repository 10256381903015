import { render, staticRenderFns } from "./ViewModelAgreementModal.vue?vue&type=template&id=5bc2984b&scoped=true&lang=pug&"
import script from "./ViewModelAgreementModal.vue?vue&type=script&lang=ts&"
export * from "./ViewModelAgreementModal.vue?vue&type=script&lang=ts&"
import style0 from "./ViewModelAgreementModal.vue?vue&type=style&index=0&id=5bc2984b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc2984b",
  null
  
)

export default component.exports